<template>
    <div id="pageTable">        
        <v-container xl fluid>
            <v-row>
                <v-overlay :value="overlay"  :z-index="999" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" lg="12">
                    <div class="margen d-flex">
                        <hr class="vr"/>
                        <p class="tblName">Dispersión</p>
                    </div>

                    <v-tabs-items v-model="tab" class="mb-10">
                        <v-tab-item :key="0" style="background:#EFEFEF;">
                            <v-col cols="12" xs="12" sm="12" md="12" class="">
                                <v-card class="borde-card big elevation-0">
                                    <v-card-text class="pa-7" >
                                        <p class="p-title mb-10">Consulta tu saldo STP</p>
                                        <ValidationObserver ref="form" v-slot="{ invalid, validated  }">
                                            <v-row>
                                                <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                    <ValidationProvider v-slot="{ errors }" name="RegistroPatronal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Registro Patronal"
                                                            :items="registroPatronal"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="registro_patronal_value_stp"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors"
                                                            id="txtRegistroPatronalStp"
                                                            ref="autoRegistroPatronal"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                                    <v-text-field 
                                                        outlined
                                                        label="Saldo actual" 
                                                        class="pa-0 ma-0" 
                                                        v-model="registro_patronal_value" 
                                                        id="txtNombre"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" >
                                                    <div class="botonAgregar">
                                                        <v-btn 
                                                            class="btnDashboard elevation-0 float-right px-6"
                                                            right
                                                            rounded 
                                                            :disabled="invalid" 
                                                        >
                                                            Consultar
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </ValidationObserver>
                                        
                                    </v-card-text>
                                </v-card>
                                <v-divider class="divider"></v-divider>
                                <v-card class="borde-card big elevation-0">
                                    <v-card-text class="pa-7" >
                                        <p class="p-title mb-10">Pagar</p>
                                        <ValidationObserver ref="form" v-slot="{ invalid, validated  }">
                                            <v-row>
                                                <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Registro Patronal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Registro Patronal"
                                                            :items="itemsRegistrosPatronales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="registroPatronalId"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                
                                                <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                    <VuetifyMultipleAutocomplete
                                                        outlined
                                                        :items="empresas"
                                                        item_text="nombre"
                                                        @dataMultiple="(data)=> empresasSeleccionadas = data"
                                                        label="Empresas"
                                                        ref="multEmpresa"
                                                        rules="required"
                                                    />
                                                </v-col>
                                                <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                    
                                                    <VuetifyMultipleAutocomplete
                                                        outlined
                                                        :items="itemsTiposNominas"
                                                        item_text="claveDescripcion"
                                                        @dataMultiple="(data)=> tiposNominasSeleccionadas = data"
                                                        label="Tipo de nómina"
                                                        ref="multTiposNomina"
                                                        rules="required"
                                                    />
                                                </v-col>
                                                <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                    <VuetifyMultipleAutocomplete
                                                        outlined
                                                        :items="itemsPeriodos"
                                                        item_text="nombre"
                                                        @dataMultiple="(data)=> periodosSeleccionadas = data"
                                                        label="Periodo"
                                                        ref="multPeriodos"
                                                        rules="required"
                                                    />
                                                </v-col>

                                                <v-col cols="12" >
                                                    <div class="botonAgregar">
                                                        <v-btn 
                                                            class="btnDashboard elevation-0 float-right px-10"
                                                            right
                                                            rounded 
                                                            @click="getMonto()"
                                                            :disabled="invalid"
                                                        >
                                                            Consultar
                                                        </v-btn>
                                                    </div>
                                                </v-col>

                                                <v-col  style="overflow: auto;">

                                                    <table class="vue-tabla">
                                                        <thead class="vue-header">
                                                            <tr>
                                                                <th class="thTitulos firstth  pl-8">Emprea</th>
                                                                <th class="thTitulos px-8">Tipo nómina</th>
                                                                <th class="thTitulos px-8">Periodo</th>
                                                                <th class="thTitulos px-8">Fecha inicio</th>
                                                                <th class="thTitulos px-8">Fecha fin</th>
                                                                <th class="thTitulos lastth  px-8">Monto</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-if="periodosPagos.length != 0">
                                                                <tr v-for="item in periodosPagos" :key="item.periodo_id" class="trBody">
                                                                    <td class="td-periodo px-8"> {{item.nombre_empresa}} </td>
                                                                    <td class="td-periodo px-8"> {{item.clave_tipo_nomina}} </td>
                                                                    <td class="td-periodo px-8"> {{item.numero_periodo}} </td>
                                                                    <td class="td-periodo px-8"> {{dateFormat(item.fecha_inicial)}} </td>
                                                                    <td class="td-periodo px-8"> {{dateFormat(item.fecha_final)}} </td>
                                                                    <td class="td-periodo px-8"> {{formatNum(item.monto)}} </td>
                                                                </tr>
                                                            </template>
                                                            <tr v-else >
                                                                <td class="vue-tabla-vacio" colspan="6">
                                                                    No hay elementos para mostrar
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0 mb-15">
                                                </v-col>
                                                
                                                <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                    <VuetifyMoneyValidate
                                                        outlined
                                                        disabled
                                                        label="Monto total"
                                                        class="pa-0 ma-0"
                                                        prefix="$"
                                                        v-model="montoTotal" 
                                                        v-bind:options="options"
                                                    />
                                                </v-col>

                                                
                                                
                                                <v-col cols="12" >
                                                    <div class="botonAgregar">
                                                        <v-btn 
                                                            class="btnDashboard elevation-0 float-right px-10"
                                                            right
                                                            rounded 
                                                            @click="pagarNomina()"
                                                            :disabled="disablePagar"
                                                        >
                                                            Pagar
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </ValidationObserver>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>

            <template >
                <v-dialog v-model="dialogDispersion" scrollable persistent width="700px" class="borde-card">
                    <v-card class="borde-card">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor" class="pt-0">
                                    <v-divider class="divider"></v-divider>
                                    <v-col xs="12" sm="12" md="12" class="pa-0 ma-0 mt-3 radio_group_01 radio-contrato" >
                                        <v-radio-group v-model="pagoNomina.tipo_pago">
                                            <template v-slot:label>
                                                <div class="mb-4">Selecciona una forma de pago</div>
                                            </template>
                                            <v-radio class="mb-4 radio-label" label="STP" value="1"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="TXT" value="2"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col v-if="pagoNomina.tipo_pago == '2'" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        
                                            <v-col class="px-0 ma-0" cols="12">
                                                Registro Patronal - {{parametrosModal.registroPatonal.nombre}}
                                            </v-col>
                                            
                                            <template v-if="parametrosModal.bancos.length > 0">
                                                <v-row class="px-0 ma-0" cols="12">
                                                    <v-col cols="12" xs="10" md="10">
                                                        <VuetifyMultipleAutocomplete
                                                            outlined
                                                            :items="itemsBancos"
                                                            item_text="displayName"
                                                            @dataMultiple="(data)=> bancosSeleccionados = data"
                                                            label="Bancos"
                                                            ref="multBancos"
                                                            rules="required"
                                                        /> 
                                                    </v-col>
                                                    <v-col cols="2" xs="2" md="2" class="d-flex justify-start mt-2">
                                                        <v-btn
                                                            fab
                                                            small
                                                            class="btnVisualizar elevation-0 float-right"
                                                            @click="previsualizarTXT()" 
                                                            :disabled="bancosSeleccionados.length == 0"
                                                        >
                                                            <v-icon class="outlined_v_icon" >visibility</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <!-- <v-row class="px-0 ma-0" cols="12" v-for="(cuenta,key2) in parametrosModal.bancos" :key="key2">
                                                    <v-col cols="12" xs="12" sm="12" md="6">{{cuenta.banco.nombre}} / No. CTA {{cuenta.cuenta_bancaria}}</v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="6" >
                                                        <v-btn
                                                            class="btnGenerar ml-2 elevation-0 float-right"
                                                            @click="descargarTXT(cuenta.banco_id)" 
                                                        >
                                                            Generar TXT
                                                        </v-btn>
                                                        <v-btn
                                                            fab
                                                            small
                                                            class="btnVisualizar elevation-0 float-right"
                                                            @click="visualizarTXT(cuenta.banco_id)" 
                                                        >
                                                            <v-icon class="outlined_v_icon" >visibility</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row> -->
                                            </template>
                                            <template v-else>
                                                <v-row class="text-center">
                                                    <v-col class="px-0 ma-0" cols="12">
                                                        No cuentas con bancos relacionados al registro patronal
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn
                                                            class="btnGenerar mr-2"
                                                            @click="$router.push({ path: 'registroPatronal' })" 
                                                        >
                                                            Agregar banco
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cerrar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="dispersarNomina()" 
                                    :loading="isSaving"
                                    :disabled="pagoNomina.tipo_pago == '2'"
                                >
                                    Dispersar
                                </v-btn>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogVisualizacionTxt" persistent max-width="700px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalVisualizacionTxt"></v-btn>
                    </template>
                        <v-card class="borde-card">
                            <perfect-scrollbar>
                                <v-card-text >
                                    <div id="padre" v-show="isLoadingVisualizacionTxt">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    <v-row class="d-flex justify-end my-10">
                                        <v-btn
                                            class="btnGenerar ml-2 elevation-0 float-right"
                                            @click="descargarTXT"
                                        >
                                            Generar TXT
                                        </v-btn>
                                    </v-row>
                                    <v-container v-show="!isLoadingVisualizacionTxt" grid-list-md id="contenedor" class>

                                        <v-row class="pa-0 mx-0 my-9 informacionGeneral">
                                            <v-row class="px-9 pt-12 pb-7">
                                                <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                    <p class="informacionGeneralTitulo">Información general</p>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Total neto:</p>
                                                    <p class="informacionGeneralText">$ {{dataGeneral.neto_pagar_total}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Total de registros:</p>
                                                    <p class="informacionGeneralText">{{dataGeneral.registros_procesados}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Periodos procesados:</p>
                                                    <p class="informacionGeneralText">{{dataGeneral.periodos_procesados}}</p>
                                                </v-col>
                                            </v-row>
                                        </v-row>

                                        <v-row class="pa-0 ma-0" v-for="(periodo,perIndex) in dataGeneral.periodos"> 
                                            
                                            <v-row class="pa-0 ma-0" v-for="rel in periodo.relaciones">
                                                <simple-table
                                                    class="tablaWidth"
                                                    :rows="rel.empleados"
                                                    :columns="headersTableEmpleados"
                                                    :perPage="[10, 25, 50, 100]"
                                                    shortPagination
                                                    :modalTable="true"
                                                >
                                                    <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                        <tr :key="element.id" v-for="(element, index) in data">
                                                            <td class="pa-0">
                                                                <expandable-item-2 :image="urlImg + element.foto"  :title="element.nombre">
                                                                    <template slot="content">
                                                                        <table class="tbl-child" width="100%">
                                                                            <tbody>
                                                                                <template>
                                                                                    <tr>
                                                                                        <td class="pt-4"><p class="subtitle">Clave del empleado: </p></td>
                                                                                        <td class="pt-4"><p class="text">{{element.clave_empleado}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Neto a pagar: </p></td>
                                                                                        <td><p class="text">$ {{element.neto_pagar}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Banco: </p></td>
                                                                                        <td><p class="text">{{element.banco}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Clabe bancaria: </p></td>
                                                                                        <td><p class="text">{{element.clabe_bancaria}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Cuenta emisora:</p></td>
                                                                                        <td>
                                                                                            <v-autocomplete
                                                                                                class="mt-4"
                                                                                                outlined
                                                                                                :items="periodo.bancos_disponibles"
                                                                                                :dense="true"
                                                                                                item-value="relacion_id"
                                                                                                v-model="element.relacion_banco_id"
                                                                                                
                                                                                            >
                                                                                            <template slot="selection" slot-scope="data">
                                                                                                <!-- HTML that describe how select should render selected items -->
                                                                                                {{ data.item.nombre_banco }} - {{ data.item.cuenta_emisora }}
                                                                                            </template>
                                                                                            <template slot="item" slot-scope="data">
                                                                                                <!-- HTML that describe how select should render items when the select is open -->
                                                                                                {{ data.item.nombre_banco }} - {{ data.item.cuenta_emisora }}
                                                                                            </template>  
                                                                                        
                                                                                            </v-autocomplete>
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                    </template>
                                                                </expandable-item-2>
    
                                                                <hr style="border: 1.5px solid #F2F2F2;" v-if="index < data.length - 1" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </simple-table>
                                            </v-row>
                                        </v-row>  
                                    </v-container>
                                </v-card-text>
                            </perfect-scrollbar>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <button class="btnCerrar modal-pull-right" @click="cerrarModalVizualizacion()">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
//APIS
import Vue from 'vue';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import pagosApi from '@/api/nominas/pagos';
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import apiTesoreria from '@/api/nominas/tesoreria';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

//COMPONENTS
import Datatable from '@/components/datatable/Datatable';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem2 from '@/components/ExpandableItem.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemPayments.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete.vue';

//PLUGINS
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        VuetifyDatePicker,
        'data-table' : Datatable,
        ExpandableItem,
        "expandable-item-2" : ExpandableItem2,
        "simple-table": DatatableSimple,
        VuetifyMoneyValidate,
        VuetifyMultipleAutocomplete,
    },
    data() {
        return {
            registro_patronal_value     : null,
            registro_patronal_value_stp : null,
            tab                         : 0,
            items                       : ['Pagar'], //['Pagar', 'Control de pagos'],
            datosLogin                  : null,
            rol                         : null,
            cliente_value               : null,
            empresa_value               : null,
            tipo_nomina_id              : null,
            periodo_value               : null,

            dialogVisualizacionTxt      : false, 
            isLoadingVisualizacionTxt   : false,
            dialogDispersion            : false,
            isSaving                    : false,
            overlay                     : false,
            fraseOverlay                : "Descargando",
            titulo_modal                : "",
            imagen                      : "/static/modal/contrato.png",
            

            itemsBancosTxt               : [],
            clientes                        : [],
            empresas                        : [],
            sucursales                      : [],
            registroPatronal                : [],
            itemsRegistrosPatronales        : [],
            registroPatronalId              : null,
            itemsTiposNominas               : [],
            itemsPeriodos                   : [],
            itemsBancos                     : [], //nuevo, para multiselect bancos
            bancosSeleccionados             : [], //nuevo, bancos seleccionados
            itemsRegistrosPatronalesCuentas : [],
            empresasSeleccionadas           : [],
            tiposNominasSeleccionadas       : [],
            periodosSeleccionadas           : [],
            headersTableEmpleados   : [
                { 
                    label       : "Empleados", 
                    align       :'center',   
                    name        : "nombre_completo",    
                    filterable  : false 
                },
            ],

            pagoNomina      : {
                tipo_pago : "1"
            },
            /* dataGeneral     : {
                cuenta_emisora      : "",
                total_importe       : "",
                total_registros     : "",
                cuenta_ordenante    : "",
                fecha_proceso       : ""
            }, */
            dataGeneral       : [],
            nomina           : {
                id : null,
                fecha_inicial : null,
                fecha_final : null , 
                fecha_de_pago : null,
                total : null,
            },
            montoTotal:null,
            periodosPagos: [],
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            parametrosModal: {
                arrayNominas            : [],
                registroPatonal         : null,
                bancos                  : []
            },
            disablePagar : true,
        }
    },
    watch: {
        /*Descripcion: Ejectuta cambiaCliente() mandando
                       el nuevo valor de esta variable*/
        cliente_value: function(newVal, oldVal) {
            if(newVal != null){
                //this.cambiaCliente(newVal);
                this.getRegistroPatronal();
                this.getRegistroPatronalPagos();
            }
        },
        registroPatronalId: function(newVal, oldVal){
            if(newVal!= null){
                this.cambiaRegistroPatronal();
            }
        },
        /*Descripcion: Ejectuta cambiaEmpresa() mandando
                       el nuevo valor de esta variable*/
        empresa_value: function(val) {  
            if(val!= null){
                //this.getTiposNominas();
            }
        },
        tipo_nomina_id: async function(val, oldVal){
            if(val != null){
                this.periodo_value = null;
                this.nomina.fecha_de_pago = null;
                this.nomina.fecha_inicial = null;
                this.nomina.fecha_final = null;
                this.nomina.total = null;
                this.montoTotal  = null;

                this.$nextTick(()=>{
                        this.$refs.FechaInicio.setDate(null);
                        this.$refs.FechaFinal.setDate(null);
                        this.$refs.FechaPago.setDate(null);
                    });

                await this.getPeriodos(val);
            } 
        },
        periodo_value: async function(val, oldVal){
            if(val != null){
                await this.getNomina();
                this.getMonto();
            } 
        },

        empresasSeleccionadas:{
            deep:true,  
            handler(val){
                this.nomina.total = null;
                this.montoTotal  = null;

                this.getTiposNominas();
            }
        },

        tiposNominasSeleccionadas:{
            deep:true,  
            handler(val){
                this.nomina.total = null;
                this.montoTotal  = null;

                this.getPeriodos(val);
            }
        },

        /* dataGeneral:{
            deep:true,
            handler(val){
                console.log(val);
            }
        } */

        /* bancosSeleccionados:{
            deep:true,
            handler(val){
                console.log(val);
            }
        } */

    },
    
    methods: {
        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @param {string} tipo string con valor 'modal' o 'tabla'
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha, tipo) {
            if(fecha == null){
                return;
            }
            else{
                let fecha2 = fecha.split("-");
                if(tipo == 'modal'){
                    return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
                }else{
                    return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
                }
            }
            
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        
        async descargarTXT(){

            /* let params = { 
                arrayNominas            : this.parametrosModal.arrayNominas, 
                registro_patronal_id    : this.parametrosModal.registroPatonal.id,
                banco_id                : banco_id,
                previsualizar           : false 
            }; */

            let periodos = this.periodosPagos.map((el) => el.periodo_id)
            let bancos = this.bancosSeleccionados

            let params = {
                periodos: periodos,
                bancos_relacion: bancos,
                relaciones: JSON.stringify(this.dataGeneral),
                accion: 'generar',
                registro_patronal_id: this.registroPatronalId
            }

            let self = this;
            const FileDownload = require("js-file-download");
            //let url = "pagos/generar-layout";
            let url ="dispersion"
            let nombre_archivo = "formato_pago.";
            self.fraseOverlay = "Descargando"
            self.overlay = true;
            try {
                await Vue.axios({
                        method: "POST",
                        responseType: "blob",
                        url: url,
                        content_type:"aplication/json",
                        headers: {
                            "content-type": "application/json",
                        },
                        data: params,
                    })
                    .then(function(response) {
                        let extensionArchivo = response.headers["content-type"].split("||");
                        extensionArchivo = extensionArchivo[0].split("/")
                        FileDownload(response.data, nombre_archivo+extensionArchivo[1]);
                        self.overlay = false;
                    })
                    .catch(async (error)=>{
                        self.overlay = false;
                        let errorString = error.response.data;
                        if (
                            error.request.responseType === 'blob' &&
                            error.response.data instanceof Blob &&
                            error.response.data.type &&
                            error.response.data.type.toLowerCase().indexOf('json') != -1
                        ){
                            errorString = JSON.parse(await error.response.data.text());

                            if(typeof errorString.error === 'string'){
                                Notify.ErrorToast(errorString.error);
                            }else{
                                let error = Object.values(errorString.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                Notify.ErrorToast(leyenda);
                            }
                        }; 
                    });
                } catch (err) {
                    Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico");
                }
        },

     /*    async visualizarTXT(banco_id){
            let params = { 
                arrayNominas            : this.parametrosModal.arrayNominas, 
                registro_patronal_id    : this.parametrosModal.registroPatonal.id,
                banco_id                : banco_id,
                previsualizar           : true 
            };
            let self = this;
            const FileDownload = require("js-file-download");
            let url = "pagos/generar-layout";
            let nombre_archivo = "formato_pago.";
            self.isLoadingVisualizacionTxt = true;
            
                await Vue.axios({
                    method: "POST",
                    url: url,
                    content_type:"aplication/json",
                    data: params,
                }).then(function(response) {
                    self.dataGeneral = response.data.generales;
                    self.itemsEmpleadosTxt = response.data.empleados;
                    console.log(response);
                    self.dialogVisualizacionTxt = true;
                    self.isLoadingVisualizacionTxt = false;
                }).catch((error)=> {
                    self.isLoadingVisualizacionTxt = false;
                    console.log(decodeURIComponent(escape(error.response.headers["content-type"])));
                    Notify.ErrorToast(decodeURIComponent(escape(error.response.headers["content-type"])));

                });
            
        }, */
        async pagarNomina(){
            this.titulo_modal = "Forma de pago";
            this.dialogDispersion = true;
        },

        //FUNCIONES GENERALES

        /*
        Entrada:
        Salida:
        Descripción: Se ejectua desde el crate para poder ver 
                     el tipo del rol del usuario loguado y en base a eso 
                     realizar validaciones por rol ademas de ejecutar las 
                     funcinones para el funcionamiento inicial del modulo.
        */
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            
            this.urlImg = GetUrlImg.getURL();

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }

            
        },

        async getRegistroPatronal(){
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            await apiRegistroPatronal.find(parametros).then((response) => {
                this.registroPatronal = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        async getRegistroPatronalPagos(){
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            await apiRegistroPatronal.find(parametros).then((response) => {
                this.itemsRegistrosPatronales = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        
        async getPeriodos(arrayTipoNominaId){

            this.itemsPeriodos = [];

            if(arrayTipoNominaId.length==0){
                return;
            }
            let param = {
                activo              : true,
                paginate            : false,
                arrayTipoNominaId   : JSON.stringify(arrayTipoNominaId),
                acumulado           : true,
                cerrado             : true,
            }
            //await apiCalculoNomina.find(param).then((response) => {
            await apiPeriodos.find(param).then((response) => {
                this.itemsPeriodos = response.data;
                this.itemsPeriodos.map(nomina => nomina.nombre = nomina.clave + ' - ' + nomina.descripcion_tn+ " - Periodo "+nomina.numero);
                //this.itemsPeriodos.map(nomina => nomina.nombre = nomina.clave_tipo_nomina + ' - ' + nomina.descripcion+ " - Periodo "+nomina.numero_periodo);
            
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron encontrar las nóminas.");
            })

            
        },

        async getNomina(){
            let param = {
                acumulado       : true,
                paginate        : false,
                periodo_id      : this.periodo_value,
                tipo_nomina_id  : this.tipo_nomina_id
            }
            await apiCalculoNomina.find(param).then((response) => {
                if(response.data[0] != undefined){
                    this.nomina = response.data[0];
                    let periodo = this.itemsPeriodos.find(item => item.id == this.nomina.periodo_id);
                    
                    this.nomina.fecha_de_pago = periodo.fecha_de_pago;
                    this.nomina.fecha_inicial = periodo.fecha_inicial;
                    this.nomina.fecha_final = periodo.fecha_final;

                    this.$refs.FechaInicio.setDate(this.nomina.fecha_inicial);
                    this.$refs.FechaFinal.setDate(this.nomina.fecha_final);
                    this.$refs.FechaPago.setDate(this.nomina.fecha_de_pago);
                }else{
                    this.periodo_value = null;
                    this.nomina.fecha_de_pago = null;
                    this.nomina.fecha_inicial = null;
                    this.nomina.fecha_final = null;

                    this.$nextTick(()=>{
                        this.$refs.FechaInicio.setDate(null);
                        this.$refs.FechaFinal.setDate(null);
                        this.$refs.FechaPago.setDate(null);
                    });
                    Notify.ErrorToast("No se pudo encontrar la nómina a pagar.");
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo encontrar la nómina a pagar.");
            })            
        },

        getMonto(){

            this.montoTotal = null;
            this.disablePagar = true;
            if (this.periodosSeleccionadas.length==0){
                return;
            }
            
            let parametros = { 
                registro_patronal_id: this.registroPatronalId,
                arrayPeriodoId:this.periodosSeleccionadas,
            }
            apiTesoreria.getPeriodosMontos(parametros).then((response) => {
                this.periodosPagos = response.periodos;
                this.montoTotal = response.total;
                this.parametrosModal.arrayNominas = response.periodos.map(nomina => nomina.nomina_id);
                this.parametrosModal.bancos = response.bancos;
                this.parametrosModal.registroPatonal = this.itemsRegistrosPatronales.find(item => item.id === this.registroPatronalId);

                //nuevo bancos multiselect
                this.itemsBancos = this.parametrosModal.bancos.map((item) => {

                    return {   
                        ...item,
                        displayName: `${item.banco.nombre} - ${item.cuenta_bancaria}`,
                    }
                        
                })
                //console.log(this.itemsBancos);
                this.disablePagar = false;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo encontrar la nómina a pagar.");
            })
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de clientes para llenar 
                     el select en el filtrado.
        */
        async getClientes() {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Empresas para llenar 
                     el select en el filtrado.
        */
        async cambiaCliente(cliente) {
            if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await empresaApi.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        async cambiaRegistroPatronal(){
            let rp = [];

            if(this.registroPatronalId == null){
                this.empresas = []
                return
            }

            rp.push(this.registroPatronalId)
            let param = {   activo: true, 
                            paginate: false, 
                            cliente_id: this.cliente_value,
                            arrayRegistroPatronal: JSON.stringify(rp)
            };

            await empresaApi.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(this.empresasSeleccionadas)
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                this.itemsTiposNominas = response.data;
                this.itemsTiposNominas.map(tipo_nomina => tipo_nomina.claveDescripcion = tipo_nomina.clave + ' - ' + tipo_nomina.descripcion+ " - "+tipo_nomina.nombre_empresa);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

         /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Sucursales para llenar
                     el select en el filtrado.
        */
        async cambiaEmpresa(empresa) {
            if(empresa == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },

        /*
        Entrada:err Array de string
        Salida:
        Descripción: Recibe un array de errores de los apis para 
                     ser mostrados con un toast.
        */
        respuesta_error(err) {
            try {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            } catch (error) {
                Notify.ErrorToast("error");
            }
        },

        previsualizarTXT(){
            
            let periodos = this.periodosPagos.map((el) => el.periodo_id)
            let bancos = this.bancosSeleccionados

            let params = {
                periodos: periodos,
                bancos_relacion: bancos,
                accion: 'previsualizar',
                registro_patronal_id: this.registroPatronalId
            }
            this.fraseOverlay = "Procesando..."
            this.overlay = true;
            this.isLoadingVisualizacionTxt = true
            apiTesoreria.dispersar(params).then((response) => {
                this.dataGeneral = response.data
                this.overlay = false;
                //console.log(this.dataGeneral);
                this.isLoadingVisualizacionTxt = false
                this.dialogVisualizacionTxt = true
            }).catch((err) => {
                this.isLoadingVisualizacionTxt = false
                this.overlay = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            })

            /* self.isLoadingVisualizacionTxt = true;
            
            await Vue.axios({
                method: "POST",
                url: url,
                content_type:"aplication/json",
                data: params,
            }).then(function(response) {
                self.dataGeneral = response.data.generales;
                self.itemsEmpleadosTxt = response.data.empleados;
                console.log(response);
                self.dialogVisualizacionTxt = true;
                self.isLoadingVisualizacionTxt = false;
            }).catch((error)=> {
                self.isLoadingVisualizacionTxt = false;
                console.log(decodeURIComponent(escape(error.response.headers["content-type"])));
                Notify.ErrorToast(decodeURIComponent(escape(error.response.headers["content-type"])));

            }); */
        },
        
        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables para cerrar un 
                     modal y dejarlo en estado inicial.
        */
        cerrarModal() {
            this.isSaving = false;
            this.dialogDispersion = false;

            //this.resetValues();
        },

        cerrarModalVizualizacion() {
            this.dialogVisualizacionTxt = false;
        },

        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables del modulo 
                     segun el rol para dejarlo en estado inicial.
        */
        resetValues() {

            this.$nextTick(() => { 
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }

                if (this.rol == "root") {
                    this.cliente_value = null;
                    this.empresa_value = null;
                    this.empresas = [];
                    this.sucursales = [];
                }
    
                if (this.rol == "admin") {
                    this.empresa_value = null;
                    this.sucursales = [];
                }

                this.itemsPeriodos          = [];
                this.itemsBancos            = [];
                this.itemsTiposNominas      = [];
                this.montoTotal             = null;
                this.nomina.fecha_de_pago   = null;
                this.nomina.fecha_final     = null;
                this.nomina.fecha_inicial   = null;
                this.nomina.total           = null;
                this.periodo_value          = null;
                this.tipo_nomina_id         = null;


                if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                    this.$refs.FechaInicio.resetDate();
                }
                if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                    this.$refs.FechaFinal.resetDate();
                }
                if(this.$refs.FechaPago != undefined || this.$refs.FechaPago != null){
                    this.$refs.FechaPago.resetDate();
                }
            });

        },
    },
    //CREATED Se ejecutan las funciones o intrucciones iniciales para el funcionamiento del modulo.
    created() {
        this.init();
    },
}
</script>


<style scoped>
    #padre {
        padding-left: 40%;
        height: 200px;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -15px;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .radio_group_01 div{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    .btnDashboard.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-transform: capitalize;
        transition: 0.3s;
        height: 38px !important;
        background: #1E2245;
        border-radius: 50px;
    }

    .btnDashboard.theme--light.v-btn:hover {
        background: #1E2245 !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
    }

    .p-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }

    .btnGenerar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        background-color: #2DB9D1 !important;
        border-radius: 80px !important;
        color: #FFFFFF !important;
        outline: none;
        text-transform: none !important;
        transition: all .2s;
        padding: 10px 23px;
    }

    .btnVisualizar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        background-color: #EFEFEF !important;
        border-radius: 150px !important;
        color: #A7A7A7 !important;
        outline: none;
        text-transform: none !important;
        transition: .3s;
        padding: 10px;
    }

    .btnVisualizar:hover {
        background-color: #FFF !important;
        color: #2DB9D1 !important;
        margin-top: -2px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
    }


    .radio_group_01{
        margin-right: 48px;
        margin-left: 5px;
    }
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
        padding-left: 15vh;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .margen {
        height: 48px;
        margin-bottom: 20px;
        margin-left: 1px;
    }
    .tr-periodo{
        margin: 20px 0px;
    }
    .td-periodo{
        /* border: 1px solid red;
        padding: 0px 10px; */
    }




    .thTitulos{
        border-width:1px; 
        border-color:#E0E0E0 ;
        text-align: left;
    }

    .vue-tabla tbody{
        background:#FFFFFF;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        margin-bottom: 10em;
    }

    .vue-tabla tbody td{
        color: #1E2144;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 2px;
        text-align: left;
    }

    .vue-tabla {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .vue-tabla .vue-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }

    .vue-tabla .vue-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
    }
    .lastth {
        border-top-right-radius: 20px !important;
    }
    .trBody{
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-bottom-style: solid;
        margin-top: 10px!important;
    }

    .vue-tabla-vacio{
        color: #c5c5c5 !important;
        background:#FFFFFF;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
</style>

